@tailwind base;
@tailwind components;
@tailwind utilities;

body {
 background-color: black;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
